import { Container, Stack, Typography } from "@mui/joy";
import { FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import TableBookmarks from "./tableBookmarks";
import { useAppSelector } from "../../redux/hooks";
import { useGetUserTripsQuery } from "../../redux/features/userTrip.api";
import { sortListBookmarks } from "../../utils/const";
import { TripSchema } from "../../utils/types";

type BookmarksProps = {}

const Bookmarks: React.FC<BookmarksProps> = () => {
  const user = useAppSelector((state) => state.auth.user)
  const { data: userTripsData, isFetching: userTripsIsFetching } = useGetUserTripsQuery({ id: user?.id })

  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('last_edited')
  const [sortOrder, setSortOrder] = useState('desc')

  const handleSort = (e: any) => {
    setSort(e.target.value)
  }

  const handleSortOrder = (e: any) => {
    setSortOrder(e.target.value)
  }

  const sortList = (sort: string, list: TripSchema[], order: string) => {
    return list.sort((a, b) => {
      let comparison = 0;
      if (sort === 'name') {
        comparison = a.name.localeCompare(b.name);
      } else if (sort === 'start_date') {
        comparison = new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
      } else if (sort === 'given_budget') {
        comparison = parseInt(a.given_budget, 10) - parseInt(b.given_budget, 10);
      } else if (sort === 'last_edited') {
        comparison = new Date(a.last_edited).getTime() - new Date(b.last_edited).getTime();
      }
      return order === 'desc' ? -comparison : comparison;
    });
  }

  const filterTrip: TripSchema[] = useMemo(() => {
    if (userTripsData?.data?.length) {
      let trips = userTripsData?.data?.filter(item => item.id && item.description)?.slice()
      if (sort) {
        trips = sortList(sort, trips, sortOrder)
      }
      if (search) {
        trips = trips.filter((item: TripSchema) =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.given_budget.toLowerCase().includes(search.toLowerCase())
        )
      }
      return trips
    }

    return []
  }, [sort, sortOrder, search, userTripsData])

  return (
    <Stack spacing={4} sx={{ padding: { lg: 4, xs: 1 } }}>
      {userTripsIsFetching && <LinearProgress />}
      <Typography sx={{ fontSize: '2rem', fontWeight: '700' }} level="h2">Bookmarks</Typography>
      <Stack spacing={2}>
        <Typography sx={{ fontSize: '1rem', fontWeight: '500' }} level="body-md">
          The place to manage your saved trips
        </Typography>
        <Stack direction={'row'} spacing={2} >
          <TextField label="Search" variant="outlined" value={search} onChange={(e) => setSearch(e.target.value)} sx={{ width: 300 }} />
          <FormControl sx={{ width: 170 }}>
            <InputLabel id="sortLabel">Sort by</InputLabel>
            <Select
              labelId="sortLabel"
              id="demo-simple-select"
              value={sort}
              label="Age"
              onChange={handleSort}
            >
              {
                sortListBookmarks.map((item, key) => (
                  <MenuItem key={key} value={item.id}>{item.label}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <FormControl sx={{ width: 170 }}>
            <InputLabel id="sortOrderLabel">Sort Order</InputLabel>
            <Select
              labelId="sortOrderLabel"
              id="sort-order-select"
              value={sortOrder}
              label="Sort Order"
              onChange={handleSortOrder}
            >
              <MenuItem value="desc">Descending</MenuItem>
              <MenuItem value="asc">Ascending</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <TableBookmarks filterTrip={filterTrip} />
      </Stack>
    </Stack>
  );
}

export default Bookmarks;