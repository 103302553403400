import { Box, Button, CardCover } from '@mui/joy';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { clientIdUnsplash } from '../../utils/env';

type CardItineraryProps = {
  date: string;
  title: string;
  text: string;
  id: string;
  subscriptionId: string;
  destination: string
};

const CardItinerary: React.FC<CardItineraryProps> = ({ date, text, title, id, subscriptionId, destination }) => {
  const [bg, setBg] = useState(undefined)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://api.unsplash.com/search/photos?query=${destination}&client_id=${clientIdUnsplash}`)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()

        setBg(data?.results[0]?.urls?.regular)
      } catch (error) {
        console.error('Error fetching itinerary data:', error)
      }
    }
    if (destination) fetchData()
  }, [destination])


  return (
    <Box className="flex flex-col w-full min-h-full gap-4 bg-white rounded-lg cursor-pointer">
      <Box className="relative h-56">
        <CardCover sx={{ '--CardCover-radius': '4px' }}>
          <img src={bg} loading="lazy" alt="Loading..." />
        </CardCover>
      </Box>
      <Box className="flex flex-col gap-2 p-4">
        <Box className="flex flex-row items-center gap-1">
          <span className="text-sm poppins-regular text-navoy_blue-600">
            {date}
          </span>
        </Box>
        <Box>
          <span className="text-xl poppins-semibold text-navoy-blue">
            {title}
          </span>
        </Box>
        <Box>
          <span className="text-base poppins-medium text-navoy_bg-700 line-clamp-3">
            {text}
          </span>
        </Box>
      </Box>
      <Box className="flex flex-row gap-2 p-2 mt-auto">
        <Link to={`/dashboard/travel/${id}/${subscriptionId}`}>
          <Button sx={{ fontSize: 14 }} size="lg">
            Edit
          </Button>
        </Link>
        <Link to={`/dashboard/export/${id}/${subscriptionId}`}>
          <Button variant="outlined" sx={{ fontSize: 14 }} size="lg">
            Export
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default CardItinerary;
