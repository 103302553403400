import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import overview1 from '../../assets/images/dashboard/overview1.png';
import overview2 from '../../assets/images/dashboard/overview2.png';
import overview3 from '../../assets/images/dashboard/overview3.png';
import overview4 from '../../assets/images/dashboard/overview4.png';
import { logout } from '../../redux/features/authSlice';
import { useGetTripOfPlanQuery, useGetUserTripsInfoQuery, useGetUserTripsQuery } from '../../redux/features/userTrip.api';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { gridLayout } from '../../utils/const';
import { formatDateRange } from '../../utils/functions';

import CardItinerary from './cardItinerary';
import CardOverview from './cardOverview';

type DashboardPageProps = {}


const DashboardPage: React.FC<DashboardPageProps> = () => {
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.auth.user)
  const { data: tripOfPlanData, refetch } = useGetTripOfPlanQuery({
    id: user?.id,
    subscription_id: user?.subscription?.currentSubscription?.eventId
  })
  const { data: userTripsData, isFetching: userTripsIsFetching } = useGetUserTripsQuery({ id: user?.id })
  const { data: userTripsInfoData, isFetching: userTripsInfoIsFetching } = useGetUserTripsInfoQuery({ id: user?.id })
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    if (!user?.subscription?.isActive) {
      toast.warn('Your plan is finished, buy a plan to enjoying our services')
    }
    if (user?.subscription?.daysRemaining === null) {
      toast.warn('Buy a plan to enjoying our services')
      dispatch(logout({}))
    }
  }, [dispatch, navigate, user?.subscription?.daysRemaining, user?.subscription?.isActive])

  useEffect(() => {
    if (tripOfPlanData) {
      refetch()
    }
  }, [refetch, tripOfPlanData])




  return (
    <Stack spacing={4} sx={{ padding: { lg: 4, xs: 1 } }}>
      {userTripsInfoIsFetching && <LinearProgress />}
      <Typography sx={{ fontSize: '2rem', fontWeight: '700' }} level="h2">Overview</Typography>
      <Box className='flex flex-wrap gap-4 '>
        <CardOverview
          value={userTripsInfoData?.data?.total_trips || 0}
          text={'Trips generated'}
          background='linear-gradient(90deg, rgba(51, 191, 255, 1) 30%, rgba(51, 191, 255, 1) 100%)'
          img={overview1}
        />
        <CardOverview
          value={
            typeof tripOfPlanData?.data === 'number' && typeof tripOfPlanData?.trips === 'number' ?
              `${tripOfPlanData?.data}/${tripOfPlanData?.trips}` :
              "0/0"
          }
          text={'Trips on your plan'}
          background='linear-gradient(90deg, rgba(255,138,128,1) 30%, rgba(255,138,128,1) 100%);'
          img={overview2}
        />
        <CardOverview
          value={userTripsInfoData?.data?.total_destination || 0}
          text={'Destinations'}
          background='linear-gradient(90deg, rgba(206,147,216,1) 30%, rgba(206,147,216,1) 100%)'
          img={overview3}
        />
        <CardOverview
          value={"$" + ((userTripsInfoData?.data?.total_cost || 0) * 0.08)}
          text={'Generated commission'}
          background='linear-gradient(90deg, rgba(128,203,196,1) 30%, rgba(128,203,196,1) 100%)'
          img={overview4}
        />
      </Box>
      <Stack direction={'row'} justifyContent={"space-between"}>
        <Typography sx={{ fontSize: '2rem', fontWeight: '700' }} level="h2">My trips</Typography>
        <Button content='a' variant='solid' sx={{ borderRadius: 40 }}
          onClick={() => navigate('/dashboard/form')}
          startDecorator={<AddIcon />}
        >New trip</Button>
      </Stack>
      {userTripsIsFetching && <LinearProgress />}
      <Grid
        container
        spacing={gridLayout.spacing}
        columns={gridLayout.columns}
        sx={{ flexGrow: 1 }}
      >
        {
          userTripsData?.data?.filter(item => item.id && item.description)?.sort((a, b) => new Date(b.creation_time).getTime() - new Date(a.creation_time).getTime())
            .map((item, key: number) => (
              <Grid xs={gridLayout.item.xs} key={key}>
                <CardItinerary
                  date={formatDateRange(item.start_date, item.end_date)}
                  title={item.name}
                  text={item.description}
                  id={item.id}
                  subscriptionId={item.subscription_id}
                  destination={item.destination?.split(',')[0] || ''}
                />
              </Grid>
            ))
        }
      </Grid>
    </Stack>
  );
}


export default DashboardPage;